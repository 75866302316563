<template>
  <v-app>
    <!-- <iframe
      src="https://www.ddlottery.com/conditions-net"
      width="100%"
      height="90%"
      class="pb-10 pt-10"
    ></iframe> -->
    <v-row>
      <v-col max-width="90vw" max-height="100%" class="px-0 mx-6">
        <v-card-text
          class="text-h5 mb-2 white--text"
          style="background-color: #172b4d"
          align="center"
          justify="center"
        >
          <span style="font-size: 20px; font-weight: 700"
            >ข้อกำหนดและเงื่อนไขบริการ และลักษณะที่สำคัญของบริการ</span
          >
          <br />
          <span style="font-size: 20px; font-weight: 700"
            >www.DDLottery.com และ www.DDlottery.net</span
          >
          <br />
          <span style="font-size: 20px; font-weight: 700"
            ><b>บริษัท มังกร ดิจิตอล จำกัด</b></span
          >
          <br />
        </v-card-text>

        <v-card-text>
          <span>
            1. DDLottery.com และ DDlottery.net โดยการบริการของบริษัท มังกร
            ดิจิตอล จำกัด
            เป็นแพลตฟอร์มตัวกลางที่ช่วยให้ผู้ขอใช้บริการสามารถเข้ามาส่งคำสั่งซื้อขายลอตเตอรี่ไปยังตัวแทนทางการ/เอเย่นต์ทางการ
            ของลอตเตอรี่พาวเวอร์บอล ที่ประเทศสหรัฐอเมริกา, เมกะมิลเลี่ยนส์
            สหรัฐอเมริกา, ยูโรแจ๊คพ๊อต (สหภาพยุโรป), พาวเวอร์บอล
            ประเทศออสเตรเลีย, มินิลอตโต้ ประเทศญี่ปุ่น, ลอตเตอรี่ลาวพัฒนา ที่
            สปป.ลาว ได้สะดวกและรวดเร็วขึ้น
          </span>
          <br />
          <span>
            2.
            ผู้ขอใช้บริการจะเปิดบัญชีลูกค้าเพื่อขอใช้บริการซื้อลอตเตอรี่กับบริษัท
            มังกร ดิจิตอล จำกัด บนเวบไซต์ www.ddlottery.com
            และฝากลอตเตอรี่ที่ซื้อไว้กับเอเย่นต์ทางการในต่างประเทศ ที่บริษัทฯ
            ได้ติดต่อไว้ตามแต่ละประเภทของลอตเตอรี่ที่เปิดให้บริการบนเวบไซต์
            www.ddlottery.com และ www.ddlottery.net
          </span>
          <br />
          <span>
            3. เมื่อผู้ใช้บริการ ได้ชำระเงินเรียบร้อยแล้ว บริษัทฯ
            จะสามารถส่งคำสั่งซื้อลอตเตอรี่ไปยังเอเย่นต์ลอตเตอรี่ทางการ
            โดยจะยืนยันหรือปฏิเสธ/ทำรายการไม่สำเร็จ
            ในรายการซื้อลอตเตอรี่กลับมาให้ลูกค้าอีกครั้ง ทั้งนี้ ddlottery.com
            และ ddlottery.net เป็นเพียงบริษัทฯ
            ให้บริการในการส่งคำสั่งซื้อให้แก่ลูกค้า
            <u style="color: red"
              >ไม่ใช่คู่ค้าลอตเตอรี่หรือผู้รับฝากลอตเตอรี่ของผู้ขอใช้บริการโดยตรง </u
            >ดังนั้น บริษัทฯ
            จะไม่รับผิดชอบการสั่งซื้อที่ถูกปฏิเสธหรือสถานะการส่งคำสั่งซื้อไม่สำเร็จในทุกกรณี
          </span>
          <br />
          <span>
            4. ปัจจุบันผู้ขอใช้บริการสามารถสั่งซื้อลอตเตอรี่ผ่าน ddlottery.com
            และ ddlottery.net ได้ แต่ยังไม่สามารถรับลอตเตอรี่ใบจริงจาก
            เอเย่นต์ทางการได้ ซึ่งโดยปกติ ทางบริษัทฯ
            จำอำนวยความสะดวกโอนเงินรางวัลให้แก่บัญชีธนาคารในประเทศไทยให้แก่ผู้ใช้บริการโดยตรงตามจำนวนเงินจริงของรางวัล
            รวมถึงจะแจ้งรายละเอียดการโอนเงินรางวัลให้ผู้ใช้บริการ
            โดยเงินรางวัลจะเป็นตามเงื่อนไขของแต่ละประเภทลอตเตอรี่ที่ตัวแทนทางการในต่างประเทศระบุไว้
          </span>
          <br />
          <span>
            5. ทางบริษัทฯ
            <u style="color: red">ไม่มีค่าธรรมเนียมและค่าคอมมิชชั่นใดๆ</u>
            ในการถูกรางวัลของลูกค้า ทั้งนี้
            ผู้ขอใช้บริการหรือลูกค้าที่เป็นผู้ถูกรางวัลจะต้องเป็นผู้รับผิดชอบในส่วนของค่าธรรมเนียมต่างๆ
            ตามจริง, ค่าภาษีต่างๆ และค่าบริการโอนต่างประเทศ
            รวมถึงส่วนต่างอัตราแลกเปลี่ยนเงินตราต่างประเทศ และค่าใช้จ่ายอื่นๆ
            (ถ้ามี) ทั้งหมดแต่เพียงผู้เดียวตามจริง
          </span>
          <br />
          <span>
            6. การขึ้นรางวัล หากเป็นรางวัลที่มูลค่าต่ำกว่า 100,000 บาท
            ทางบริษัทฯ ยินดีโอนเงินรางวัลล่วงหน้าให้แก่ผู้ถูกรางวัลภายใน 24-48
            ชม. แทนเอเย่นต์ในต่างประเทศให้แก่ผู้ใช้บริการ
            ตามอัตราแลกเปลี่ยนเงินต่างประเทศธนาคารกสิกรไทย
          </span>
          <br />
          <span>
            7. กรณีเงินรางวัลมีมูลค่ามากกว่า 100,000 บาท
            อาจใช้เวลาในการขึ้นเงินรางวัลนานมากกว่าปกติ ทั้งนี้
            ขึ้นอยู่กับเงื่อนไขและระยะเวลาโอนเงินรางวัลตามจริงของเอเย่นต์ทางการในต่างประเทศ
            โดยบริษัทฯ
            จะแจ้งให้ลูกค้าทราบถึงขั้นตอนและระยะเวลาอีกครั้งเมื่อลูกค้าถูกรางวัล
          </span>
          <br />
          <span>
            8. บัญชีลูกค้าผู้ขอใช้บริการสามารถดูประวัติการสั่งซื้อ
            และรายการถูกรางวัล หรือรายการเคลื่อนไหวบัญชี ได้บน เวบไซต์
            www.ddlottery.com และ www.ddlottery.net
          </span>
          <br />
          <span>
            9. ถึงแม้บริษัทฯ
            จะเป็นผู้อำนวยความสะดวกดำเนินการขึ้นรางวัลโอนเงินรางวัลให้แก่ลูกค้า
            ในบางกรณี สำหรับบางรางวัลใหญ่ต่างๆ
            และโดยเฉพาะอย่างยิ่งรางวัลแจ๊คพ๊อต ทางเอเย่นต์ทางการในต่างประเทศ
            อาจต้องการให้ลูกค้าเป็นผู้เดินทางไปรับรางวัลโดยตรงด้วยตนเอง ณ
            ประเทศเจ้าของรางวัลลอตเตอรี่ (ลอตเตอรี่พาวเวอร์บอล
            ที่ประเทศสหรัฐอเมริกา, เมกะมิลเลี่ยนส์ สหรัฐอเมริกา, ยูโรแจ๊คพ๊อต
            (สหภาพยุโรป), พาวเวอร์บอล ประเทศออสเตรเลีย, มินิลอตโต้
            ประเทศญี่ปุ่น, ลอตเตอรี่ลาวพัฒนา ที่ สปป.ลาว) โดย ddlottery
            จะเป็นผู้แนะนำและช่วยอำนวยความสะดวกให้แก่ลูกค้าเพื่อการติดต่อไปรับรางวัลโดยตรงกับเอเย่นต์ทางการ
            โดยค่าใช้จ่ายจะเป็นความรับผิดชอบของลูกค้าทั้งหมด
          </span>
          <br />
          <span>
            10. หากมีการโต้แย้งความผิดพลาด ในกรณีเลขสลากไม่ตรงกับที่สั่งซื้อ
            หรือ มีการปฏิเสธการสั่งซื้อจากต่างประเทศทุกกรณี
            ก่อนเวลาประกาศผลรางวัล การสั่งซื้อนั้นจะถูกยกเลิกโดยอัตโนมัติ
            และลูกค้าสามารถแจ้งขอรับเงินโอนคืนตามจำนวนเงินที่ชำระมา 100%
            หรือลูกค้าสามารถกดสั่งซื้อใหม่ได้อีกครั้งโดยไม่ต้องชำระเพิ่มอีกครั้ง
            สำหรับการสั่งซื้อจำนวนสลากเท่าเดิมในงวดถัดไป
          </span>
          <br />
          <span>
            11. ทางบริษัทฯ ขอสงวนสิทธิ์ ไม่แสดงที่มาของรัฐหรือเมือง
            เลขประจำตัวแทนที่ออกสลาก เนื่องจากจะทำให้ตัวแทนถูกตัดสิทธิ์การขาย
            กรณีที่นำมาให้ผู้อื่นขายต่อ เนื่องจากตัวแทนไม่ขายตรงด้วยตนเอง
            (เหมือนกรณีตัวแทนจำหน่ายสลากลอตเตอรี่ในประเทศไทย)
            หากลูกค้าได้กดสั่งซื้อแล้ว และก่อนการออกรางวัลสลากนั้น
            ได้แจ้งไม่ตกลงตามเงื่อนไขข้อ 11 นี้ ทางบริษัทฯ
            ขอแจ้งคืนเงินเต็มจำนวนลูกค้าใน 24 ชม.
            และให้ถือเป็นการยกเลิกออเดอร์คำสั่งซื้อและเป็นอันสิ้นสุด
          </span>
          <br />
          <span>
            12. ลูกค้าสามารถสแกนบาร์โค้ดเพื่อตรวจสอบสลากได้
            โดยผ่านระบบการตรวจสอบบาร์โค้ดจากประเทศของลอตเตอรี่นั้นๆ ทั้งนี้
            การสแกนสลาก อาจจะทำให้บาร์โค้ด
            และขนาดของใบสลากที่สแกนผิดเพี้ยนจากขนาดจริง
            อาจทำให้สแกนไม่ติดได้ในบางรูป โดยบริษัทฯ
            ขอสงวนสิทธิ์ไม่สแกนเพิ่มเติมให้อีกครั้ง หากลูกค้าได้กดสั่งซื้อแล้ว
            และก่อนการออกรางวัลสลากนั้น ได้แจ้งไม่ตกลงตามเงื่อนไขข้อ 12 นี้
            ทางบริษัทฯ ขอแจ้งคืนเงินเต็มจำนวนลูกค้าใน 24 ชม.
            และให้ถือเป็นการยกเลิกออเดอร์คำสั่งซื้อและเป็นอันสิ้นสุด
          </span>
          <br />
          <span>
            13. ผู้ขอใช้บริการจะทำการตรวจสอบเอกสาร การยืนยัน ข้อมูล
            และเอกสารต่าง ๆ ทั้งหมดที่ส่งถึงผู้ขอใช้บริการผ่านทางแพลตฟอร์ม
            www.ddlottery.com และ www.ddlottery.net
            (โดยเฉพาะอย่างยิ่งเอกสารรูปภาพสแกนลอตเตอรี่ที่ส่งผ่านบัญชีลูกค้า
            ddlottery.net) อย่างละเอียดรอบคอบทันทีที่ได้รับเอกสารดังกล่าว
            และจะดำเนินการแจ้งให้ทราบถึงการโต้แย้งความถูกต้องใด ๆ
            ในเอกสารดังกล่าวเป็นลายลักษณ์อักษรก่อนวันและเวลาประกาศผลรางวัลนั้นๆ
            นับจากที่ได้รับข้อมูลและเอกสารรูปภาพสแกนลอตเตอรี่ใบจริงดังกล่าวผ่านทางแพลตฟอร์ม
            ddlottery
            โดยในกรณีที่ไม่มีการโต้แย้งดังกล่าวจากผู้ขอใช้บริการภายในกำหนดเวลาข้างต้นให้ถือว่าการยืนยัน
            ข้อมูล และ/หรือเอกสารต่าง ๆ
            ข้างต้นถูกต้องและมีผลผูกพันผู้ขอใช้บริการทุกประการ
          </span>
          <br />
          <span>
            14. หากผู้ใช้บริการสั่งซื้อในช่วงเวลา 2-3 ชม. ก่อนการประกาศผลรางวัล
            (ช่วงระหว่างงวด) การสั่งซื้อของลูกค้าอาจจะถูกตัดเป็นงวดปัจจุบัน
            หรืออาจจะส่งไปเป็นคำสั่งซื้อของงวดถัดไปอัตโนมัติทันที
            กรุณาตรวจสอบวันประกาศรางวัลบนสำเนาสแกนสลากฯ
            ของท่านในประวัติการสั่งซื้อ
          </span>
          <!-- <br />
          <span>
            15. หากผู้ใช้บริการสั่งซื้อในช่วงเวลา 3-4 ชม. ก่อนการประกาศผลรางวัล
            (ช่วงระหว่างงวด) การสั่งซื้อของลูกค้าอาจไม่สำเร็จ
            หรือาจถูกตัดเป็นงวดปัจจุบัน
            หรืออาจจะส่งไปเป็นคำสั่งซื้อของงวดถัดไปอัตโนมัติทันที
            กรุณาตรวจสอบวันประกาศรางวัลบนสำเนาสแกนสลากฯ
            ของท่่านในประวัติการสั่งซื้อทุกครั้ง
          </span> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn dense color="primary" @click="goBack()"> ถอยกลับ </v-btn>
          <!-- <v-btn
          dense
          color="primary"
          @click="
            form.isConsent = true;
            dialog = false;
          "
        >
          ฉันยอมรับ
        </v-btn> -->
        </v-card-actions>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>